.content-area {
  background: $globalOffWhite;
}

.press-search {
  background: $globalOffWhite;
  padding-bottom: 2rem;

  &__input-wrapper {
    display: flex;
    align-items: stretch;

    input[type="text"] {
      margin-bottom: 0;
      border-radius: 50px;
      padding-left: 1.2rem;
    }

    button {
      flex-grow: 1;
      display: flex;
      height: auto;
      padding: 0;
      margin: 0;
      margin-left: -60px;
      justify-content: center;
      width: 60px;
      line-height: inherit;
      align-items: center;
      // width: auto;
      font-size: 1.8rem;
      background: none;
      border: 0;
      color: $globalDark;
    }
  }

  .container {
    max-width: 600px;
  }
}

.press-item {
  display: flex;
  flex-direction: column;
  height: auto;
  border-radius: 30px;
  box-shadow: rgba(0, 0, 0, 0.2) 0 0 10px;
  background: $white;
  // min-height: 350px;

  h3 {
    font-size: 1.2rem;

    > a {
      color: $globalDark;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  &__image {
    background: $globalOffWhite;
    border-radius: 30px 30px 0 0;
    max-height: 200px;

    img {
      border-radius: 30px 30px 0 0;
      width: 100%;
      max-height: 200px;
      object-fit: cover;
    }
  }

  &__date {
    color: $globalPink;
    margin-bottom: 0;
  }

  &__button {
    margin-top: auto;

    i {
      color: $globalBlue;
    }

    .btn {
      padding: 0;
    }
  }

  &__inner {
    padding: 1.5rem;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    height: 100%;
    margin-bottom: 0;
  }
}

.carousel-slides--press:not(.glide--slider) {
  .carousel-slides__inner {
    .glide__slides {
      gap: 40px;
      justify-content: center;
      padding-top: 1rem;
      padding-bottom: 1rem;
    }
  }

  .press-item {
    max-width: 100%;

    @include respond-to("md") {
      max-width: calc(50% - 20px);
    }

    @include respond-to("lg") {
      max-width: calc(33% - 40px);
    }
  }

  .glide__bullets {
    display: none;
  }

  &.carousel-slides--downloads {
    .glide__bullet {
      @include respond-to("lg") {
        display: none;

        &:nth-child(4n + 1) {
          display: block;
        }
      }
    }
  }
}

.section-title {
  text-align: center;

  &.section-title--heading {
    background: rgba($globalPink, 0.35);
    // color: $white;
    margin-top: 3rem;
    padding: 1rem;
    border-radius: 50px;
    border: 1px dashed $globalPink;
  }
}

.search {
  padding-bottom: 1rem;

  &--posts {
    .press-item {
      width: 100%;
      max-width: calc(33% - 40px);
    }
  }

  &--files {
    .press-item {
      width: 100%;
      max-width: calc(25% - 40px);
    }
  }

  &__inner {
    display: flex;
    width: 100%;
    gap: 40px;
    row-gap: 40px;
    flex-direction: column;
    flex-wrap: wrap;

    @media screen and ( min-width: 768px ) {
      justify-content: center;
      flex-direction: row;
    }

    .press-item {
      flex-grow: 1;

      @media screen and ( max-width: 767px ) {
        max-width: 100%;
      }
    }
  }
}

.carousel-slides {
  @include respond-to("md") {
    padding-right: 50px;
    padding-left: 50px;
  }

  .carousel-slides__inner {
    .glide__slides {
      padding: 0 0 4rem;
      white-space: normal;
    }
  }

  .glide__bullets {
    position: relative;
    background: $globalSkyBlue;
    padding: 0.875rem;
    border-radius: 30px;
    margin-bottom: 0;
    bottom: 1rem;

    .glide__bullet {
      background: $white;
      box-shadow: none;

      @include respond-to("lg") {
        display: none;

        &:nth-child(3n + 1) {
          display: block;
        }
      }
    }

    .glide__bullet--active {
      background: $globalBlue;
    }
  }
}

.press-slider,
.downloads-slider {
  padding: 3rem 0;
}

.tags {
  display: flex;
  list-style: none;
  flex-wrap: wrap;
  gap: 5px;
  row-gap: 5px;
  margin-bottom: 1rem;

  &.tags--presscentre {
    margin-bottom: 0;
    padding: 1rem 0 2rem;
    justify-content: center;
    gap: 20px;
    row-gap: 20px;

    li {
      a {
        padding: 0.8rem 1.2rem;
        font-size: 1rem;

        &.active {
          background-color: transparent !important;
          border: 3px solid $globalDark;
        }
      }
    }
  }

  li {
    display: flex;
    align-items: center;
    margin: 0;

    a {
      border: 3px solid transparent;
      border-radius: 50px;
      padding: 0.4rem 0.7rem;
      line-height: 1.1;
      font-size: 0.875rem;
      font-weight: 900;
      text-transform: capitalize;
      text-align: center;
      color: $globalDark;
      text-decoration: none;
    }
  }
}

.page-press-centre-article {
  background: $white;

  .press-centre-header {
    padding-top: 220px;
    background-color: $pressCentre;
    padding-bottom: 7rem;

    @include respond-to-max("md") {
      padding-bottom: 4rem;
    }
  }

  p {
    &:last-child {
      margin-bottom: 0;
    }
  }

  h1 {
    text-align: center;
  }

  .content-area {
    background: none;
  }

  .container__narrow {
    padding-bottom: 3rem;
  }

  .post__content {
    padding: 3rem 0 0;
    font-size: 1rem;
    font-family: "Gotham Rounded", arial, sans-serif;
    font-weight: 300;

    blockquote {
      position: relative;

      @extend .pink-highlight;

      padding-right: 6rem;

      &::after {
        content: "\"";
        position: absolute;
        top: -3rem;
        right: 0;
        display: block;
        height: 1px;
        font-size: 10rem;
        color: $globalPink;
      }
    }

    ul {
      margin-left: 30px;
      list-style: disc;
      margin-bottom: 2rem;

      li {
        font-family: "Gotham Rounded", arial, sans-serif;
        padding-left: 1rem;
        font-size: 1rem;
        line-height: 1.5;
      }
    }

    a:not(.btn) {
      font-family: "Gotham Rounded", Arial, Helvetica, sans-serif;
      font-weight: 600;
      color: $linkColour;
      text-decoration: none;

      &:hover {
        color: darken($linkColour, 15);
        text-decoration: underline;
      }
    }
  }

  .date {
    color: $globalPink;
    text-align: center;
    margin-top: 1rem;
    font-size: 1.2rem;
  }

  .featured__image {
    margin-top: -8rem;
    width: 100%;

    @include respond-to-max("md") {
      margin-top: -5rem;
    }

    img {
      width: 100%;
      box-shadow: 0 0 15px rgba(0, 0, 0, 0.16);
    }
  }
}

.go-back {
  width: 100%;
  display: flex;

  a.btn {
    margin-left: auto;
    margin-right: auto;
  }
}
