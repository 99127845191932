.awards {
  background: none;
  padding: 3rem 0 3rem;

  &.bg--global-darkpurple {
    color: white;
  }

  &__content-inner {
    padding: 3rem 0;
  }

  &__item {
    position: relative;
  }

  .award__image {
    display: flex;
    margin-left: auto;
    margin-right: auto;

    &--award {
      position: absolute;
      bottom: 0;
      left: 70%;
      transform: translateX(-50%);
      max-height: 65%;
    }
  }

  .awards-carousel:not(.glide--slider) {
    .awards__images {
      display: flex;
      justify-content: center;
      padding: 0;
      width: 100%;
      gap: 40px;
    }

    .awards__item {
      flex: 0 0 33.333333%;
      max-width: 33.333333%;
      width: 100%;
      position: relative;
      justify-content: center;
    }

    .glide__bullets {
      display: none;
    }
  }

  .glide__bullets {
    position: relative;
    background: $globalSkyBlue;
    padding: 0.875rem;
    border-radius: 30px;
    margin-bottom: 0;
    bottom: 0;

    .glide__bullet {
      background: $white;
      box-shadow: none;

      @include respond-to("lg") {
        display: none;

        &:nth-child(3n + 1) {
          display: block;
        }
      }
    }

    .glide__bullet--active {
      background: $globalBlue;
    }
  }

  &__bg {
    display: flex;
    width: 50%;
    height: 100%;
    z-index: -1;
    justify-content: flex-start;
    align-items: flex-start;
    position: absolute;
    top: -40%;
    right: 0;
    pointer-events: none;

    svg {
      width: 100%;
      max-width: 100%;
      height: 150%;
      z-index: 10;
      transform: translateX(50%);

      @include respond-to-max("md") {
        width: 600px;
        max-width: none;
      }
    }
  }
}
