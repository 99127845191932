.multi-image-block {
  .container {
    padding: 6rem 0;

    @include respond-to-max("md") {
      padding: 1rem 0;
    }

    &.container__narrow {
      padding: 0;

      .content-before {
        padding-bottom: 0;
      }

      .content-after {
        padding-top: 0;
      }
    }
  }

  &__content-inner {
    padding: 3rem 0 4rem;

    @include respond-to-max("sm") {
      text-align: left !important;
      padding-left: 1rem;
      padding-right: 1rem;

      p {
        text-align: left !important;
      }
    }
  }

  &__inner {
    padding: 5rem 0;
    margin-bottom: 3rem;
    width: 100%;
    display: flex;
    position: relative;
    justify-content: center;

    @include respond-to-max("md") {
      padding: 1rem 0;
      margin-bottom: 1rem;
    }
  }

  &__bg {
    display: flex;
    width: 100%;
    height: 100%;
    z-index: -1;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    pointer-events: none;

    svg {
      position: absolute;
      width: 100%;
      max-width: 80%;
      height: auto;

      @include respond-to-max("md") {
        width: 600px;
        max-width: none;
      }
    }
  }

  p > .btn {
    margin-top: 0;
  }

  &__images {
    width: 100%;
    max-width: 1000px;
    display: flex;
    flex-direction: row;
    gap: 50px;
    position: relative;
    z-index: 6;

    @include respond-to-max("md") {
      flex-wrap: wrap;
      flex-direction: column;
      padding-left: 2rem;
      padding-right: 2rem;
      gap: 1rem;
    }
  }

  &__image {
    display: flex;
    max-width: 100%;

    @include respond-to("lg") {
      &:nth-child(2) {
        margin-top: -3rem;
      }
    }

    @include respond-to-max("md") {
      &:nth-child(2) {
        position: absolute;
        right: 2rem;
        top: 50%;
        transform: translateY(-50%);
      }
    }

    img {
      width: 300px;
      height: 300px;
      object-fit: cover;
      border-radius: 30px;

      @include respond-to-max("md") {
        width: 150px;
        height: 150px;
      }
    }
  }
}
