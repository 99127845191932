.u-bg-left {
  background-position-x: left;
}

.u-bg-right {
  background-position-x: right;
}

.u-bg-center {
  background-position-x: center;
}

.u-bg-offscreen {
  &--left {
    background-position: -50% 0;
  }

  &--right {
    background-position: -50% 0;
  }
}

.pink-highlight {
  font-size: 1rem;
  color: darken($globalPink, 20);
  background-color: rgba($pressCentre, 0.3);
  border: 2px dashed $pressCentre;
  padding: 1rem;
  margin-bottom: 2rem;
  font-family: "Gotham Rounded Light", arial, sans-serif;
  line-height: 1.5;
  font-weight: 600;

  strong {
    font-family: "Gotham Rounded", arial, sans-serif;
    font-weight: 900;
  }

  a:not(.btn) {
    color: darken($globalPink, 30) !important;
  }
}

.blue-highlight {
  color: #6269af;
}

.highlight {
  font-size: 1rem;
  color: $globalDark;
  border: 2px dashed $globalDark;
  padding: 1rem;
  margin-bottom: 2rem;
  font-family: "Gotham Rounded Light", arial, sans-serif;
  line-height: 1.5;
  font-weight: 600;

  strong {
    font-family: "Gotham Rounded", arial, sans-serif;
    font-weight: 900;
  }
}
