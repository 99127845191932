.contentBlock {
  &__imageText,
  .image-text-container {
    @include flex-center;

    @include respond-to-max("md") {
      display: block;
      padding: 3rem 0 3rem 0;
    }

    @include respond-to-max("sm") {
      padding: 0;
    }

    .text--block {
      a:not(.btn) {
        font-family: "Gotham Rounded", Arial, Helvetica, sans-serif;
        font-weight: 600;
        color: $linkColour;
        text-decoration: none;

        &:hover {
          color: darken($linkColour, 15);
          text-decoration: underline;
        }
      }
    }

    .text {
      margin-bottom: 1rem;

      p {
        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    &--intro {
      text-align: center;
      padding: 3em 0;
    }

    &--range {
      .contentBlock__text {
        text-align: center;
      }
    }

    &--first {
      padding-top: 3em;
    }

    &--last {
      padding-bottom: 3em;
    }
  }

  &__imageText {
    overflow: hidden;

    .contentBlock__img,
    .contentBlock__text {
      flex: 0 0 50%;
      display: flex;
    }

    .contentBlock__text {
      padding: 0 0 0 50px;

      .text--block {
        img {
          max-width: 100% !important;
          height: auto;
        }
      }

      @include respond-to-max("sm") {
        padding: 0;
      }
    }

    .contentBlock__img {
      // padding: 30px 0;
      overflow: visible;

      picture img {
        -moz-transform: translateX(-10%) translateY(0);
        -webkit-transform: translateX(-10%) translateY(0);
        -o-transform: translateX(-10%) translateY(0);
        -ms-transform: translateX(-10%) translateY(0);
        transform: translateX(-10%) translateY(0);

        @include respond-to-max("md") {
          -moz-transform: translateX(0) translateY(0);
          -webkit-transform: translateX(0) translateY(0);
          -o-transform: translateX(0) translateY(0);
          -ms-transform: translateX(0) translateY(0);
          transform: translateX(0) translateY(0);
        }

        &[src$=".svg"] {
          transform: none;
          width: 100%;
        }
      }
    }

    &.u-image-right,
    &--alt {
      .image-text-container {
        flex-direction: row-reverse;
      }

      .contentBlock__img {
        justify-content: flex-end;

        picture img {
          -moz-transform: translateX(10%) translateY(0);
          -webkit-transform: translateX(10%) translateY(0);
          -o-transform: translateX(10%) translateY(0);
          -ms-transform: translateX(10%) translateY(0);
          transform: translateX(10%) translateY(0);

          @include respond-to-max("md") {
            -moz-transform: translateX(0) translateY(0);
            -webkit-transform: translateX(0) translateY(0);
            -o-transform: translateX(0) translateY(0);
            -ms-transform: translateX(0) translateY(0);
            transform: translateX(0) translateY(0);
          }
        }
      }

      .contentBlock__text {
        justify-content: flex-start;
        padding: 0 0 0 50px;

        @include respond-to-max("sm") {
          padding: 0;
        }
      }
    }

    .contentBlock__img {
      text-align: center;

      picture img {
        height: auto;
        width: 100%;
      }

      @include respond-between("ipAirPort", "mobLand") {
        picture img {
          width: 100%;
          height: auto;
        }
      }

      @include respond-to-max("lg+") {
        picture img {
          width: 100%;
          height: auto;
        }
      }

      @include respond-to-max("md") {
        justify-content: center;
        //margin-bottom: -50px;

        picture img {
          width: 100%;
          height: auto;
        }
      }
    }

    .contentBlock__text {
      .text--block {
        width: 100%;
        max-width: 75%;

        .btn {
          margin: 0;
        }
      }

      @include respond-to-max("md") {
        padding: 0;

        .text--block {
          max-width: 100%;
          padding: 0 15px;
          // text-align: center;

          .btn {
            margin: 0 auto;
          }
        }
      }
    }
  }
}
