.site-header {
  position: fixed;
  display: flex;
  flex-flow: row nowrap;
  width: 100%;
  background: $globalHeaderBG;
  padding: 15px;

  @include respond-to-max("small-lap") {
    padding: 0 15px;
  }

  @include respond-to-max("ipAirPort") {
    padding: 20px 0;
  }
}

.site-header__container {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: flex-start;
}

.site-header__burger {
  cursor: pointer;
  height: 40px;
  position: absolute;
  left: 30px;
  top: 45px;

  span {
    position: relative;
    display: block;
    width: 32px;
    height: 6px;
    background: $white;
    border-radius: 20px;
    transition: 0.3s all ease-in-out;
    top: 50%;

    &::before,
    &::after {
      position: absolute;
      content: "";
      display: block;
      width: 32px;
      height: 6px;
      border-radius: 20px;
      background: $white;
      top: -12px;
      transition: 0.3s all ease-in-out;
    }

    &::after {
      top: 12px;
    }
  }

  &.active {
    span {
      background-color: transparent;

      &::before,
      &::after {
        transform: rotate(45deg);
        top: 0;
      }

      &::after {
        transform: rotate(-45deg);
        top: 0;
      }
    }
  }
}

.site-header__menu {
  position: absolute;
  left: 0;
  top: 135px;
  width: 100%;
  max-width: 430px;

  @include transform(translateX(-100%));
  @include border-radius(0, 0, 50px, 0);
  @include transition(250ms);

  overflow: hidden;

  ul {
    li {
      a {
        display: block;
        background: $white;
        font-size: 1.1em;
        font-family: "Gotham Rounded", arial, sans-serif;
        text-decoration: none;
        padding: 15px;
        color: $globalDark;
      }

      // Backgrounds for nav items
      @each $name, $BGcolor in $ourColors {
        &.#{$name} {
          a {
            background: $BGcolor;
          }
        }
      }
    }
  }

  &.active {
    @include transform(translateX(0%));
  }
}

.site-header__logo {
  flex-grow: 1;
  display: flex;
  justify-content: center;
  text-align: center;
  margin: 0 2em 0 0;
  margin: 0 auto;

  svg {
    width: 136px;
    height: auto;
  }

  @include respond-to("lg") {
    flex-grow: 0;
  }
}

// styles for breadcrumbs

.breadcrumb--container {
  position: absolute;
  top: 136px;
  width: 100%;
  background: rgba(255, 255, 255, 0.85);
  padding: 15px 0;
  z-index: 5;

  .breadcrumbs {
    ul {
      display: flex;
      align-items: center;

      li {
        margin: 0;

        a {
          color: $ttgrey;
          text-decoration: none;
          display: block;
          font-size: 0.875em;
          line-height: 1em;
          padding: 0 20px;
          position: relative;
          font-weight: 400;
          font-family: "Gotham Rounded", Arial, Helvetica, sans-serif;
          text-transform: capitalize;

          @include respond-to-max("sm") {
            padding: 0 5px;
            font-size: 0.6em;
          }
        }

        &:first-of-type {
          a {
            padding-left: 0;
          }
        }
      }
    }

    .break-chevron {
      margin: 0 10px;

      &::before {
        font-family: "Font Awesome 6 free", Arial, Helvetica, sans-serif;
        content: "\f054";
        font-weight: 900;
        display: block;
      }

      @include respond-to-max("sm") {
        margin: 0 2px;
        font-size: 0.8em;
      }
    }
  }

  @include respond-to-max("small-lap") {
    padding: 15px 10px;
  }

  @include respond-to-max("ipAirLand") {
    padding: 15px 10px;
  }

  @include respond-to-max("ipAirPort") {
    top: 137px;
  }

  @include respond-to-max("md") {
    top: 137px;
  }
}
