.hero {
  width: 100%;
  height: 100vh;
  overflow: hidden;
  color: $white;
  position: fixed;
  left: 0;
  top: 0;

  &__container {
    &--fixed {
      // position: fixed;
      width: 100%;
      height: 100%;
      z-index: 0;
      overflow: hidden;

      .videoWrapper {
        position: absolute;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100%;
        z-index: 2;
        pointer-events: none;
        overflow: hidden;
        // padding-bottom: 56.25%;

        // @include respond-to-max("mobLand") {
        //   display: none;
        // }
      }

      .hero__bg--video {
        iframe {
          width: 100vw;
          height: 56.25vw;
          min-height: 100vh;
          min-width: 177.77vh;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }

      .hero__bg--image {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;

        img {
          object-fit: cover;
          width: 100%;
          height: 100%;
        }
      }

      .hero__overlay {
        position: absolute;
        left: 0;
        top: 0;
        background: $ttgrey;
        opacity: 0.25;
        width: 100%;
        height: 100%;
      }

      .hero__text {
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        width: 100%;
        height: 100%;

        @include respond-to-max("md") {
          h1 {
            text-align: center;
          }
        }
      }

      .hero__scrollDown {
        position: absolute;
        width: 100%;
        left: 0;
        bottom: 20px;
        display: flex;
        justify-content: center;
        z-index: 50;

        .fa-chevron-down {
          font-size: 2rem;
        }
      }
    }

    .hero__video {
      // padding-bottom: 56.25%;
      position: relative;
      width: 100%;
      height: 100%;
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;

      .video__overlay {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        z-index: 2;
        display: none;

        .video__thumb {
          width: 100%;
          height: 100%;

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }

        .video__play {
          width: 175px;
          height: 175px;
          position: absolute;
          margin: auto;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          background: rgba(255, 255, 255, 0.7);

          @include border-radius(100%);
          @include transition(250ms);

          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 5em;

          &:hover {
            cursor: pointer;
            background: rgba(255, 255, 255, 1);
          }
        }

        &.active {
          display: none;

          + .videoWrapper {
            iframe {
              display: block;
            }
          }
        }

        @include respond-to-max("mobLand") {
          display: none;
        }
      }

      iframe {
        width: 100vw;
        height: 56.25vw;
        min-height: 100vh;
        min-width: 177.77vh;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }

      @include respond-to-max("mobLand") {
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
      }
    }
  }

  & + section,
  & + div {
    position: relative;
    z-index: 0;
    margin-top: 100vh;
  }

  &--standard {
    text-align: center;

    .hero__background {
      position: fixed;
      left: 50%;
      top: 50%;
      width: 100%;
      height: 100%;
      overflow: hidden;
      transform: translate(-50%, -50%);

      svg {
        width: 100%;
        height: auto;
        transform: translate(0%, -15%);

        path {
          fill: $gold;
        }
      }

      @include respond-to-max("ipAirPort") {
        transform: translate(0%, 0%);
        left: 0;
        top: 0;

        svg {
          width: auto;
          height: 100%;
          transform: translate(-10%, 0%);
        }
      }

      @include respond-to-max("md") {
        transform: translate(0%, 0%);
        left: 0;
        top: 0;

        svg {
          width: auto;
          height: 100%;
          transform: translate(-25%, 0%);
        }
      }
    }

    .hero__container {
      &--fixed {
        .hero__text {
          &--contents {
            width: 100%;
            max-width: 1200px;

            @include respond-to-max("md") {
              padding: 0 15px;
            }
          }

          @include respond-to-max("small-lap") {
            padding-top: 10%;
          }

          @include respond-to-max("sm") {
            padding-top: 30%;
          }
        }
      }
    }

    &.contentBlock__bg--standard {
      color: $ttgrey;

      .hero__background {
        svg {
          path {
            fill: rgba(255, 255, 255, 0.25);
          }
        }
      }
    }
  }

  &--short {
    height: 80vh;

    & + section,
    & + div {
      margin-top: 80vh;

      @include respond-to-max("sm") {
        margin-top: 100vh;
      }
    }

    @include respond-to-max("sm") {
      height: 100vh;
    }
  }

  &--half {
    height: 50vh;

    & + section,
    & + div {
      margin-top: 50vh;

      @include respond-to-max("sm") {
        margin-top: 50vh;
      }
    }

    @include respond-to-max("sm") {
      height: 50vh;
    }
  }
}
