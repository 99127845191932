.download-block {
  padding: 3em 0;

  .container {
    h3 {
      font-family: "Gotham Rounded Bold", arial, sans-serif;
      font-size: 2em;
      margin: 0 0 1em 0;
    }

    picture {
      img {
        -webkit-box-shadow: 0 0 5px 5px rgba(0, 0, 0, 0.25);
        box-shadow: 0 0 5px 5px rgba(0, 0, 0, 0.25);
        margin: 0 auto;
      }
    }
  }

  .downloads {
    width: 100%;
    display: flex;
    justify-content: center;
    gap: 50px;

    @include respond-to-max("lg") {
      flex-wrap: wrap;
    }

    @include respond-to-max("sm") {
      flex-direction: column;
      padding: 0 1rem;
    }

    .download-item {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 100%;
      max-width: 33.333%;

      @include respond-to-max("md") {
        max-width: 50%;
      }

      @include respond-to-max("sm") {
        max-width: 100%;
      }

      &__image {
        img {
          max-width: 100%;
          box-shadow: 0 0 15px rgba(0, 0, 0, 0.16);
        }
      }
    }
  }
}
