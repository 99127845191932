.contentBlock__global {
  padding: 5em 0;

  h3 {
    & + p {
      font-size: 1.063em;
    }
  }

  .row {
    ul {
      &.location-list {
        @include flex-center;

        gap: 30px;
        flex-wrap: wrap;
        margin: 6em 0 0 0;

        li {
          &.item {
            width: 18%;
            list-style: none;
            text-align: center;

            .item--image {
              svg {
                width: 100%;
                height: auto;
                max-width: 190px;
                max-height: 140px;
                margin: 0 auto;
                margin-bottom: 1em;

                path {
                  fill: $ourImpact;

                  @include transition(250ms);
                }
              }
            }

            .item--text {
              p {
                font-family: "Gotham Rounded", arial, sans-serif;
                line-height: 1em;
                font-size: 1em;
                margin: 0;
              }
            }

            @include respond-to-max("sm") {
              width: 45%;
            }

            &:hover {
              cursor: pointer;

              .item--image {
                svg {
                  path {
                    fill: $globalSkyBlue;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.locationsListing {
  background: $globalSkyBlue;

  .locations {
    display: none;

    &.active {
      display: flex;
    }
  }
}

.locations {
  padding: 3rem 0;
}

.glide-locations {
  display: flex;
  width: 100%;

  .glide__track {
    width: 100%;
  }

  .glide__slides {
    width: 100%;
    white-space: normal;
    gap: 40px;
  }

  .glide__bullets {
    display: flex;
    background: $white;
    padding: 0.875rem;
    border-radius: 30px;
    margin-bottom: 0;
    bottom: -1.5rem;

    .glide__bullet {
      background: $globalSkyBlue;
      box-shadow: none;

      @include respond-to("lg") {
        display: none;

        &:nth-child(3n + 1) {
          display: block;
        }
      }
    }

    .glide__bullet--active {
      background: $globalBlue;
    }
  }

  .glide__arrows {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    pointer-events: none;

    > button {
      pointer-events: all;
      background: none;
      border: 0;
      color: $white;
      font-size: 3rem;

      // &[data-glide-dir="<"] {
      //   margin-left: -1.5rem;
      // }

      // &[data-glide-dir=">"] {
      //   margin-right: -1.5rem;
      // }
    }
  }

  &:not(.glide--slider) {
    .glide__slides {
      @include respond-to-max("sm") {
        flex-direction: column;
      }
    }

    .location {
      max-width: 100%;
    }

    .glide__bullets,
    .glide__arrows {
      display: none;
    }
  }

  .location {
    display: flex;
    flex-direction: column;
    font-size: 0.875rem;
    text-align: center;
    width: 100%;
    max-width: 33%;
    padding: 0;
    font-weight: 400;
    line-height: 1.2;
    font-family: "Gotham Rounded", Arial, Helvetica, sans-serif;

    strong {
      font-weight: 700;
    }

    p {
      margin-bottom: 0.875rem;
    }
  }

  &.glide--slider {
    .glide__slides {
      gap: 0;
    }
  }
}
