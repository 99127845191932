@keyframes slideDown {
  from {
    top: -30px;
    opacity: 0;
  }

  to {
    top: 0;
    opacity: 1;
  }
}

.tabbedNav {
  &.contentBlock__bg {
    overflow: visible;
  }

  .tabbed-cards-carousel {
    width: 100%;
    padding: 2rem 2rem 4rem;

    @include respond-to-max("md") {
      padding: 2rem 2rem 2rem;
    }

    @include respond-to-max("sm") {
      padding: 1rem 1rem 1rem;
    }
  }

  .container {
    max-width: 1440px;
  }

  &--list {
    padding-top: 3rem;
    display: flex;
    gap: 20px;
    justify-content: center;
    width: 100%;

    li {
      list-style: none;
      text-align: center;
      margin: 0;
      max-width: 200px;

      @include respond-to("lg") {
        min-width: 200px;
        max-width: 200px;
      }

      @include border-radius(30px, 30px, 0, 0);

      a {
        text-decoration: none;
        height: auto;

        i {
          color: $globalDark;
        }

        .icon {
          display: flex;
          justify-content: center;
          align-items: flex-end;
          min-height: 150px;
          max-height: 150px;

          svg {
            width: 80%;
            height: auto;
            max-height: 100px;
            margin: 0 auto;
            margin-bottom: 1rem;
          }

          img {
            width: 80%;
            height: auto;
            margin: 0 auto;
            margin-bottom: 1rem;

            &[src$=".svg"] {
              width: 100%;
              max-width: 80%;
              max-height: 100px;
              height: auto;
            }
          }
        }

        .title {
          padding: 0 1rem 1rem 1rem;

          p {
            margin: 0;
            color: $ttgrey;
            font-family: "Gotham Rounded", arial, sans-serif;
            font-size: 0.875em;
            line-height: 1.2em;
          }
        }
      }

      .fa-solid {
        display: none;
      }
    }

    @include respond-to-max("sm") {
      flex-wrap: wrap;
      padding: 1em;

      li {
        width: 45%;
        position: relative;

        @include border-radius(25px);

        a {
          .title {
            padding: 0 0.5em 1em 0.5em;
          }
        }

        .fa-solid {
          display: block;
          position: absolute;
          left: 0;
          bottom: 10px;
          width: 100%;
        }

        @include respond-to-max("sm") {
          padding-bottom: 20px;
        }
      }
    }
  }

  &--panels {
    flex-direction: column;
    list-style: none;
    width: 100%;
    display: none;
    opacity: 0;

    &.active {
      display: flex;
      animation-name: slideDown;
      animation-duration: 0.5s;
      opacity: 1;
    }

    .achievements-title {
      text-align: center;
    }

    .contentBlock__text {
      padding: 0 0 0 25px;

      .text--block {
        max-width: 90%;
      }

      @include respond-to-max("sm") {
        padding: 1rem;
        width: 100%;
        flex: 0 0 100%;

        .text--block {
          max-width: 100%;
        }
      }
    }

    &.contentBlock__imageText,
    &.contentBlock__bg {
      overflow: visible;
    }

    .image-text-container {
      padding: 4rem 0;

      &.contentBlock__bg {
        overflow: visible;
      }

      .contentBlock__img {
        justify-content: center;

        picture {
          width: 100%;
          text-align: center;

          img[src$=".svg"] {
            max-width: 80%;
            margin-left: auto;
            margin-right: auto;

            @include respond-to-max("sm") {
              max-width: 100%;
              transform: none !important;
            }
          }
        }
      }
    }

    &.u-image-right,
    &:nth-child(odd) {
      .image-text-container {
        flex-direction: row-reverse;

        .contentBlock__text {
          justify-content: flex-end;
          padding: 0 25px 0 0;
        }

        .contentBlock__img {
          picture {
            img {
              transform: translateX(10%) translateY(0);
            }
          }
        }
      }
    }

    &.u-image-left {
      .image-text-container {
        flex-direction: row;

        .contentBlock__text {
          justify-content: flex-start;
        }

        .contentBlock__img {
          picture {
            img {
              transform: translateX(-10%) translateY(0);
            }
          }
        }
      }
    }
  }

  @include respond-to-max("sm") {
    padding: 2em 0;
  }
}
