.carousel {
  background: $white;

  .glide__track {
    .glide__slides {
      .slide {
        margin-bottom: 0;

        img {
          width: 100%;
          height: auto;
        }
      }
    }
  }
}

.contentBlock {
  &__carousel--tile {
    padding: 5em 0;

    .container {
      max-width: 1200px;
    }

    .glide-tile {
      position: relative;
      padding: 0 0 3em 0;

      .glide__track {
        overflow-x: visible !important;

        .glide__slides {
          overflow-x: visible !important;

          .slide {
            width: 33.33%;

            .slide--content {
              @include border-radius(20px);

              -webkit-box-shadow: 5px 5px 15px #00000029;
              box-shadow: 5px 5px 15px #00000029;
              overflow: hidden;
              position: relative;
              padding-bottom: 35px;
              height: 100%;

              .slide--image {
                height: 190px;
                width: 100%;
                overflow: hidden;

                img {
                  width: 100%;
                  height: auto;
                  margin: 0 auto;
                }
              }

              .slide--date {
                padding: 1em 1em 0 1em;

                p {
                  line-height: 1em;
                  margin: 0;
                  font-size: 0.7em;
                  font-family: "Gotham Rounded", arial, sans-serif;
                  color: $globalPink;
                }
              }

              .slide--text {
                padding: 0.5em 1em 0 1em;

                h4 {
                  font-size: 1.1em;
                  line-height: 1.2em;
                  white-space: break-spaces;
                }
              }

              .slide--cta {
                padding: 0 1em 0.5em 1em;
                position: absolute;
                left: 0;
                bottom: 0;
                width: 100%;

                .btn {
                  margin: 0;
                  text-transform: none;
                  font-size: 0.7em;
                  padding: 0;

                  .icon {
                    right: -15px;
                    top: 2px;
                  }

                  &:hover {
                    color: $globalBlue;
                  }
                }
              }
            }
          }
        }
      }

      .glide__bullets {
        position: absolute;
        bottom: 0;
        padding: 5px 2px;
        background: $globalSkyBlue;

        @include border-radius(20px);

        .glide__bullet {
          background: $white;
          border: none;
          margin: 0 0.3em;
          width: 12px;
          height: 12px;

          &--active {
            background: $globalDark;
          }
        }
      }
    }

    .container {
      @include respond-to-max("sm") {
        padding: 0;
      }
    }
  }
}
