.wysiwyg {
  &__inner {
    padding: 3rem 0 1rem;
  }

  a:not(.btn) {
    font-family: "Gotham Rounded", Arial, Helvetica, sans-serif;
    font-weight: 600;
    color: $linkColour;
    text-decoration: none;

    &:hover {
      color: darken($linkColour, 15);
      text-decoration: underline;
    }
  }
}
