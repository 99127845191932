.contentBlock {
  &__tabbedContent {
    padding: 5em 0;
    display: block;

    .image-text-container {
      margin: 0 auto;

      .contentBlock__img {
        picture {
          img {
            @include transform(translateX(0) translateY(0));
          }
        }
      }

      .contentBlock__text {
        padding: 0 0 0 50px;

        .text--block {
          max-width: 100%;
        }
      }
    }

    &.u-image-right,
    &.contentBlock__imageText--alt {
      .image-text-container {
        flex-direction: row-reverse;

        .contentBlock__text {
          padding: 0 50px 0 0;

          @include respond-to-max("sm") {
            padding: 0;
          }
        }
      }
    }

    @include respond-to-max("ipAirLand") {
      .image-text-container {
        padding: 0 15px;
      }
    }

    @include respond-to-max("sm") {
      padding: 2.5em 0;

      .image-text-container {
        padding: 0 15px;

        .contentBlock__text {
          padding: 0;
        }
      }
    }

    .contentBlock--carousel {
      width: 100%;
      max-width: 1440px;
      margin: 0 auto;
      padding: 3em 0;

      .glide-content {
        position: relative;

        .glide__track {
          overflow-x: visible !important;

          .glide__slides {
            overflow-x: visible !important;

            .slide {
              width: 33.33%;

              .slide--content {
                @include border-radius(20px);

                overflow: hidden;
                position: relative;
                padding: 2em 0;
                height: 100%;
                background: $globalOffWhite;

                .slide--image {
                  width: 100%;
                  overflow: hidden;

                  img {
                    width: 100%;
                    height: auto;
                    margin: 0 auto;
                    max-width: 110px;
                  }
                }

                .slide--text {
                  padding: 0.5em 1em 0 1em;

                  p {
                    font-size: 0.8em;
                    white-space: break-spaces;
                    font-family: "Gotham Rounded", arial, sans-serif;
                  }
                }
              }
            }
          }
        }

        .glide__bullets {
          position: absolute;
          bottom: -45px;
          padding: 5px 2px;
          background: $globalSkyBlue;

          @include border-radius(20px);

          .glide__bullet {
            background: $white;
            border: none;
            margin: 0 0.3em;
            width: 12px;
            height: 12px;

            &--active {
              background: $globalDark;
            }
          }
        }
      }
    }
  }
}
