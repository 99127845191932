// Required Core Stylesheet
@import "node_modules/@glidejs/glide/src/assets/sass/glide.core";
// Optional Theme Stylesheet
@import "node_modules/@glidejs/glide/src/assets/sass/glide.theme";
@import "settings/fonts";
@import "settings/variables";
@import "settings/colours";
@import "settings/utilities";
@import "utility/reset";
@import "utility/mixins";
@import "utility/utilities";
@import "site/header";
@import "site/footer";
@import "components/buttons";
@import "components/typography";
@import "components/forms";
@import "components/header";
@import "components/body";
@import "components/hero";
@import "components/contentBlock";
@import "components/fullWidthBlock";
@import "components/fullWidthImageBlock";
@import "components/imageTextBlock";
@import "components/productListing";
@import "components/rangeListing";
@import "components/contactBlock";
@import "components/categoryListBlock";
@import "components/product";
@import "components/category-popup";
@import "components/multi-column-block";
@import "components/table-block";
@import "components/retail-block";
@import "components/block-quote";
@import "components/videoBlock";
@import "components/accordian";
@import "components/flow";
@import "components/carousel";
@import "components/pageListing";
@import "components/guidlinesListing";
@import "components/single-product";
@import "components/standardTextBlock";
@import "components/global-reach";
@import "components/tabbedNav";
@import "components/tabbedContent";
@import "components/wysiwyg";
@import "components/content-with-cards";
@import "components/multi-image-block";
@import "components/awards";
@import "components/download-block";
@import "components/commitment-block";

// pages
@import "pages/pressCentre";

/**
* This was added here temporarily for demo purposes.
*/

body {
  background: $ttgrey;
  // color: white;
  font-family: "Gotham Rounded", Helvetica, sans-serif;
  font-weight: 300;
  font-style: normal;
}

.block {
  background: darken($ttgrey, 5);
  border: dotted 4px white;
  padding: 20px;
  margin: 20px 0;
}

// Style overwrites for cookie button

#ot-sdk-btn.ot-sdk-show-settings {
  padding: 7px 20px !important;
  line-height: 1.5em !important;
  color: $white !important;
  font-size: 1.25em !important;
  border: 3px solid $ttgrey !important;
  margin-bottom: 2em !important;
}

#ot-sdk-btn.ot-sdk-show-settings:hover {
  background: white !important;
  color: $gold !important;
}

img {
  &.fr-fic {
    margin-left: auto;
    margin-right: auto;

    &.fr-fir {
      margin-left: auto;
      margin-right: 0;
    }

    &.fr-fil {
      margin-left: 0;
      margin-right: auto;
    }
  }
}
