.contentBlock {
  .container {
    position: relative;
    z-index: 1;
  }

  &__fullWidth {
    position: relative;
    padding: 100px 0;

    .background {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0%;
      left: 0;
      z-index: 0;

      svg {
        width: 100%;
        height: auto;
        position: absolute;
        left: 0%;
        top: -10%;
      }
    }

    ul {
      margin-left: 30px;
      list-style: disc;
      margin-bottom: 2rem;

      li {
        font-family: "Gotham Rounded", arial, sans-serif;
        padding-left: 1rem;
        font-size: 1rem;
        line-height: 1.5;
      }
    }

    a:not(.btn) {
      font-family: "Gotham Rounded", Arial, Helvetica, sans-serif;
      font-weight: 600;
      color: $linkColour;
      text-decoration: none;

      &:hover {
        color: darken($linkColour, 15);
        text-decoration: underline;
      }
    }

    @include respond-to-max("xl") {
      .background {
        svg {
          top: -10%;
        }
      }
    }

    @include respond-to-max("lg+") {
      padding: 100px 0;
    }

    @include respond-to-max("ipAirPort") {
      padding: 40px 0;

      .background {
        svg {
          top: -10%;
          width: 200%;
          left: -60%;
        }
      }
    }

    @include respond-to-max("md") {
      padding: 40px 0;

      .background {
        top: -5%;

        svg {
          top: 0;
          width: 300%;
          left: -100%;
        }
      }
    }

    &.contentBlock__imageText {
      padding: 100px 0;
    }

    &.contentBlock__imageText--alt {
      overflow: hidden;

      .background {
        left: 40%;
        top: -30%;
        width: 150%;
        height: 150%;
      }
    }

    .container {
      .content__video {
        position: relative;
        padding-bottom: 56.25%;
        display: block;

        iframe {
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          z-index: 1;
        }

        .video__overlay {
          position: absolute;
          width: 100%;
          height: 100%;
          left: 0;
          top: 0;
          z-index: 2;

          .video__play {
            width: 175px;
            height: 175px;
            position: absolute;
            margin: auto;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            background: rgba(255, 255, 255, 0.7);

            @include border-radius(100%);
            @include transition(250ms);

            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 5em;

            img {
              padding: 0;
            }

            &:hover {
              cursor: pointer;
              background: rgba(255, 255, 255, 1);
            }
          }

          &.active {
            display: none;
          }

          @include respond-to-max("ipAirLand") {
            display: none;
          }
        }
      }

      p {
        img {
          display: inline-flex;
          padding: 0 15px;

          @include respond-to-max("sm") {
            display: block;
          }
        }
      }
    }

    &--lp {
      padding: 50px 0;
    }

    .inline-images {
      display: flex;
      justify-content: space-evenly;
      align-items: baseline;

      @include respond-to-max("sm") {
        display: block;

        img {
          width: 50%;
          display: block;
          margin: 0 auto;
        }
      }
    }
  }

  &__bg {
    .background {
      svg {
        path {
          fill: rgb(237, 211, 166);
        }
      }
    }
  }
}
