.commitment-card {
  padding: 1rem;

  .container__narrow {
    max-width: 40%;

    @include respond-to-max("ipAirLand") {
      max-width: 80%;
    }

    @include respond-to-max("lg") {
      max-width: 100%;
    }
  }

  .card {
    padding: 0 3rem;
    border-radius: 30px;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.16);
    margin-bottom: 1rem;

    @include respond-to-max("md") {
      padding: 0 1.5rem;
    }
  }

  .card__inner {
    padding-top: 2rem;
    padding-bottom: 2rem;
    max-width: 60%;
    width: 100%;
    margin-left: auto;
    margin-right: auto;

    @include respond-to-max("md") {
      max-width: 100%;
    }

    p {
      &:last-child {
        margin-bottom: 0;
      }
    }

    img {
      max-width: 100%;
    }
  }

  .card__images {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 2rem;

    @include respond-to-max("md") {
      flex-wrap: wrap;
      gap: 1rem;
    }

    img {
      max-width: 150px;
    }
  }
}
