.content-with-cards {
  position: relative;
  padding: 3rem 0;

  &__icon {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: 1rem;
  }

  .background-svg {
    position: absolute;
    top: 0;
    left: 0;
    width: auto;
    height: 100%;
    z-index: -1;
    overflow: hidden;

    @include respond-to-max("sm") {
      display: none;
    }

    &.u-bg-offscreen {
      &--left {
        svg {
          margin-left: -50%;
        }
      }

      &--right {
        left: auto;
        right: 0;

        svg {
          margin-left: 50%;
        }
      }
    }

    path {
      fill: $globalYellow;
    }
  }
}

.cards-carousel.glide--slider,
.cards-carousel.glide--carousel {
  .cards {
    flex-wrap: nowrap;

    .card {
      margin: 0;
      height: auto;
    }
  }

  .glide__bullets {
    display: flex;
    background: $white;
    padding: 0.875rem;
    border-radius: 30px;
    margin-bottom: 0;
    bottom: -1.5rem;

    .glide__bullet {
      background: $globalSkyBlue;
      box-shadow: none;

      @include respond-to("lg") {
        display: none;

        &:nth-child(3n + 1) {
          display: block;
        }
      }
    }

    .glide__bullet--active {
      background: $globalBlue;
    }
  }
}

.tabbed-cards-carousel {
  .glide__bullets {
    display: none;
  }
}

.tabbed-cards-carousel.glide--slider {
  .cards {
    flex-wrap: nowrap;
    flex-grow: 0;
    gap: 0;
    margin: 0;
    padding: 0;
    justify-content: flex-start;

    .card {
      flex-grow: 0;
      margin: 0;
    }
  }

  .glide__bullets {
    display: flex;
    background: $globalSkyBlue;
    padding: 0.4rem 0.875rem;
    border-radius: 30px;
    margin-bottom: 0;
    bottom: 1.2rem;

    .glide__bullet {
      background: $white;
      box-shadow: none;

      @include respond-to("lg") {
        display: none;

        &:nth-child(3n + 1) {
          display: block;
        }
      }
    }

    .glide__bullet--active {
      background: $globalBlue;
    }
  }
}

.cards-carousel {
  .glide__bullets {
    display: none;
  }
}

.cards {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  width: 100%;

  .card {
    width: 100%;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    border-radius: 30px;
    text-align: center;
    margin: 30px;
    max-width: 28%;
    height: auto;

    @media screen and ( max-width: 768px ) {
      max-width: 100%;
    }

    &__image {
      margin: 0 auto;
      padding: 2rem 2rem 0;
      min-height: 160px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    &__inner {
      padding: 2rem;
    }
  }
}
