// Colours
$white : #ffffff;
$black : #000000;
$ttgrey: #25303b;
$ttyellow: #e6c183;
$gold  : #e0c38a;
$darkGold: #dba74e;
$grey: #bdc0c4;

// Trans colours
$transWhite: rgba(255, 255, 255, 0.5);

// Product colours
$bottleFeeding: #e6dfd2;
$sleeping: #cce1e0;
$breastFeeding: #f7e7e6;
$breastFeedingAlt: #e5afad;
$sterilising: #c4edef;
$nappyDisposal: #d6e5dc;
$soothing: #bce0cf;
$healthcare: #f7cdca;
$cupsAndFeeding: #e5e7ba;
$pageBG: #fbf6ed;
$aboutBG: #f4e4ca;

// Group website Colurs
$globalPink: #cd80b4;
$globalDark: #25303b;
$globalBlue: #6269af;
$globalSkyBlue: #94d4eb;
$globalOffWhite: #f2f2f2;
$globalYellow: #f9dc9a;
$globalPurple: #bca7d1;
$globalDarkPurple: #9a74b1;
$globalGreen: #a5d3b7;
$globalHeaderBG: rgba(37, 48, 59, 0.88);

$linkColour: #6f88bf;

$ourStory: #f29cbf;
$ourBrand: #72c7d5;
$ourPeople: #bca7d1;
$ourImpact: #a5d3b7;
$pressCentre: #fdecf5;
$contactUs: #f9dc9a;

$valuesTogether: #c4d3ee;

$ourColors:
  "our-story" $ourStory,
  "our-brand" $ourBrand,
  "our-people" $ourPeople,
  "our-impact" $ourImpact,
  "press" $pressCentre,
  "contact" $contactUs;

$categoryColours:
  "white" $white,
  "global-pink" $globalPink,
  "global-dark" $globalDark,
  "global-blue" $globalBlue,
  "global-skyblue" $globalSkyBlue,
  "global-offwhite" $globalOffWhite,
  "global-yellow" $globalYellow,
  "global-green" $globalGreen,
  "global-purple" $globalPurple,
  "global-darkpurple" $globalDarkPurple,
  "global-headerbg" $globalHeaderBG,
  "our-story" $ourStory,
  "our-brand" $ourBrand,
  "our-people" $ourPeople,
  "our-impact" $ourImpact,
  "press" $pressCentre,
  "contact" $contactUs,
  "values-together" $valuesTogether;

// flow colours
$variflow: #f9db80;
$slowflow: #bcd78e;
$medflow: #7dcfee;
$fastflow: #f0924a;

// media queries
$breakpoints: (
  "xs" : 321px,
  "sm" : 480px,
  "md" : 768px,
  "ipAirPort" : 821px,
  "mobLand" : 845px,
  "lg" : 1024px,
  "lg+" : 1025px,
  "ipAirLand" : 1181px,
  "xl" : 1200px,
  "small-lap" : 1367px,
) !default;

// colours
$primary-color: #cccccc;

// buttons
$btn-primary: #cccccc;

// layout
$max-width-containers: 1440px !default;

// auto cards
$autocards-ldesk-max-items: 5;
$autocards-desk-max-items: 4;
$autocards-tab-max-items: 3;
$autocards-mob-max-items: 1;
